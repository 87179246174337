@use '../../style.scss';

.header-expandable-container {
  width: 100%;
}
.plain-text {
  font-size: 12px;
}

.expand-icon,
.edit-icon {
  cursor: pointer;
  border-radius: 6px;
  background-color: transparent;
  border: none;
  padding: 6px;
  &:hover {
    background-color: style.$bluegray-50;
  }
}

.header-top {
  .alternativ-info {
    .alternativ-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 24px;
      font-size: 0.875rem;
    }
  }
  // talked to Lars Erik and he says it's good to have absolute posititoning here
  // bc it is fixed to the relative position from the parent (container)
  .expand-icon {
    top: 12px;
    right: 12px;
    position: absolute;
  }
}

.header-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .buffer-icon-and-text {
    display: flex;
    align-items: center;
    .buffer-icon {
      display: inline-flex;
      margin-right: 6px;
    }
    .buffer-text {
      display: inline-flex;
    }
  }
  .edit-icon {
    margin-right: 12px;
    border-radius: 6px;
    padding: 6px;
    &:hover {
      background-color: style.$bluegray-50;
    }
  }
}

.line-seperator {
  width: 100%;
  height: 1px;
  background: style.$bluegray-50;
  flex: none;
  order: 0rem;
  align-self: stretch;
  flex-grow: 0rem;
}

// TODO: add < > type of navigation when window is too small and overflow is hidden?
.phase-picker {
  overflow: hidden;
  text-overflow: ellipsis;
}
