@use '../../style.scss';

.project-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.project-button {
  @extend %button-shared;
  &:disabled {
    background-color: style.$gray-200;
    cursor: default;
  }
}
.projects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 2em;
}
.project-container {
  position: relative;
  background-color: style.$white;
  border-radius: 10px;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
  margin: 0.5em;
  padding: 1em;
  width: 400px;

  .project-heading {
    display: flex;
    justify-content: space-between;

    &-action-panel {
      display: none;
    }
  }

  &:hover {
    .project-heading {
      display: flex;
      justify-content: space-between;

      &-action-panel {
        display: flex;
        justify-content: end;
        position: absolute;
        margin-top: 15px;
        right: 15px;
      }
      &-icon-button {
        scale: 0.6;
        border: 0;
        color: style.$bluegray-400;
        background: style.$white;
        cursor: pointer;
        &:hover {
          color: style.$bluegray-800;
        }
        &:disabled {
          color: style.$gray-200;
          cursor: default;
        }
      }
    }
  }
}
.project-text {
  &-buffer {
    font-size: 0.8em;
    color: style.$gray-800;
    margin-top: 0;
  }
}
.project-link {
  color: black;
  text-decoration: none;
  background-color: red;
}

.new-project-container {
  background-color: style.$bluegray-50;
  border-radius: 10px;
  margin: 0.5em;
  padding: 1em;
  width: 400px;
}
.new-project-icon-button {
  width: 100%;
  height: 100%;
  margin: auto;
  border: 0;
  color: style.$bluegray-300;
  background: transparent;
  cursor: pointer;
}
