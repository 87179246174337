@use '../../style.scss';

.button {
    background: style.$bluegray-800;
    color: white;
    padding: 5px;
    font-size: 1.5em;
    .button-waiting {
        align-items: right;
    }
}

.button:hover {
    border-color: style.$bluegray-200;
    background: style.$bluegray-800;
}

.button:disabled {
    background: style.$bluegray-200;
    color: style.$bluegray-500;
}

