@use '../../style.scss';

.image-container-wrapper {
  flex: 1;
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.image-container-row {
  display: flex;
}

.image-container {
  display: flex;
  flex-direction: column;
  background-color: style.$white;
  border-radius: 12px;
  padding: 5px;
  padding-bottom: 0;
  margin-top: 0px;
  margin-right: 10px;
  border: none;
  border: 1px solid black;
  padding-left: 10px;
}

.image-container-active {
  @extend .image-container;
  background-color: style.$bluegray-50;
}

img {
  width: 200px;
  height: 200px;
  border-radius: 12px;
}

.text-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}
.score-container {
  border: solid 2px style.$tilstandsgrad-0;
  border-radius: 6px;
  padding: 4px;
  color: style.$tilstandsgrad-0;
  font-weight: 600;
  background-color: #fff;
}
.score-container-good {
  @extend .score-container;
  border-color: style.$tilstandsgrad-1;
  color: style.$tilstandsgrad-1;
}
.score-container-medium {
  @extend .score-container;
  border-color: style.$tilstandsgrad-2;
  color: style.$tilstandsgrad-2;
}
.score-container-bad {
  @extend .score-container;
  border-color: style.$tilstandsgrad-3;
  color: style.$tilstandsgrad-3;
}
