@import 'https://js.arcgis.com/4.22/esri/themes/light/main.css';
.App {
  font-family: sans-serif;
  text-align: center;
}

.map-wrapper {
  height: 100%;
  width: 100%;
  min-height: 100%;
}
.map-picker-container {
  width: 100%;
  height: 100%;
}
.esri-view .esri-view-surface--inset-outline:focus::after {
  outline: none !important;
}

/* PopupStyles.css */
.popup-content {
  font-size: 16px;
}

.popup-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px;
}
