body {
  background: #0e101c;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.form {
  background: #ffffff;
  max-width: 400px;
  margin: 0 auto;
}

p::before {
  display: inline;
  content: '⚠ ';
}

input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

hr {
  margin-top: 30px;
}

.form-button {
  margin-left: auto;
  position: absolute;
  right: 20px;
}
