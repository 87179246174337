@use '../../style.scss';

.svg-icon {
    position: relative;
    top: .125em;
    flex-shrink: 0;
    height: 1em;
    width: 1em;
    padding-left: 0px;
    animation-name: rotate;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes rotate{
    from{ transform: rotate(-360deg); }
    to{ transform: rotate(360deg); }
}
