@use '../../style.scss';

.map-page-box {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}
.page-container {
  display: flex;
  flex-direction: row;
  height: calc(100% - style.$header-size);
  width: 100%;
  position: fixed;
}
.container-map {
  display: flex;
  flex-direction: column;
  width: inherit;
  height: 100%;
  &-table {
    @extend .container-map;
  }
}
.table-container {
  z-index: 10;
  background-color: white;
  max-width: 100%;
}
.drag-div {
  width: 100%;
  height: 8px;
  display: flex;
  background-color: white;
  cursor: row-resize;
  border-top: 1px solid black;
}
.expand-div {
  position: relative;
  z-index: inherit;
  overflow: auto;
}
.drag-icon {
  background-color: gray;
  height: 2px;
  width: 30px;
  margin: auto;
  margin-top: 3px;
}
.navigation-menu {
  margin: 4px;
  pointer-events: all;
}

.navigation-box {
  position: fixed;
  height: calc(100vh - style.$header-size - 40px);
  z-index: 100;
  left: 0;
  display: flex;
  flex-direction: row;
  width: 100vw;
  pointer-events: none;
  * {
    pointer-events: all;
  }
  * > {
    margin: 2rem;
  }
}
.project-link {
  color: black;
  text-decoration: none;
  background-color: red;
}

.data-container {
  display: flex;
  flex-direction: column;
}

.tool-box {
  position: fixed;
  top: 1em; /* Adjust the top position */
  left: 15vw; /* Adjust the right position */
  z-index: 150;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  display: flex; /* Set the container to flex */
  align-items: center; /* Align items vertically within the container */
}

.tool-box h3 {
  margin: 0;
  padding-bottom: 5px;
  font-size: 16px;
}

.tool-box button {
  margin-right: 10px; /* Add spacing between buttons */
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tool-box button:hover {
  background-color: #b3b3b3;
}

.project-version-buttons button {
  margin-right: 1px; /* Add spacing between buttons */
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  background-color: style.$orange-500;
  transition: background-color 0.3s ease;
}

.project-version-buttons button:hover {
  background-color: style.$orange-800;
}

/* Adjust the styles for the DropdownSelect and ProjectBrowser components */
.tool-box > div {
  margin-right: 10px; /* Add spacing between elements */
}

.form-container {
  position: absolute;
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0px 2px 5px rgba(150, 25, 25, 0.1);
  max-width: 400px;
  width: 100%;
  box-sizing: border-box;
}
