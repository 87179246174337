@use '../../style.scss';
%general-container {
  background-color: #fff;
  display: flex;
  border-radius: 12px;
  box-shadow: 0 0 4px #818285;
  height: calc(100vh - style.$header-size - 52px);
  position: relative;
  transition: width 0.7s ease-in;
  flex-direction: column;
  margin: 0.4rem;
  transition: width 0.5s ease-in;
  padding: 12px;
  flex-direction: column;
}

// TODO: when window reaches minimized container size it switches from expanded to minimized?
// TODO: fix smoother transition of the swap between minimized vs. expandable content?
.expandable-container {
  @extend %general-container;
  width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.minimized-container {
  @extend %general-container;
  width: 320px;
}
