@use '../../style.scss';

.version-card,
.version-card-expanded {
  //border-bottom: solid 1px #063342;
  padding: 10px 20px;
}
.version-card {
  cursor: pointer;
}
.version-header {
  font-size: 1em;
}
.calculate-button {
  @extend %button-shared;
  font-size: 1em;
  margin-bottom: 10px;
}
.action-bar {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.icon-button {
  border: 0;
  color: style.$bluegray-800;
  background: transparent;
  cursor: pointer;

  &:disabled {
    color: style.$gray-200;
    cursor: default;
  }
}
