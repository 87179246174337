@import 'https://js.arcgis.com/4.22/esri/themes/light/main.css';

.plot-wrapper {
  position: absolute; // Ensures child absolute/fixed elements are positioned relative to this
  top: 0;
  right: 0;
  height: 50vh;
  padding: 4em;
  background-color: white;
}
.plot-wrapper.full-screen {
  position: fixed; // Ensures child absolute/fixed elements are positioned relative to this
  top: 0;
  left: 0;
  width: 100vw; // Full width
  height: 100vh; // Full heigh
  z-index: 1000; // Ensures its above every other component
}
