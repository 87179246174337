@use "../../style.scss";

.container {
  background-color: style.$bluegray-10;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr [main-content] 5fr [footer] 1fr;
  grid-template-columns: 1fr [left-content] 4fr [right-content] 1fr;
}

.main-content {
  grid-row: main-content;
  grid-column: left-content;
  display: grid;
  grid-template-columns: [left] 3fr [right] 3fr;
}

.title-and-description {
  grid-column: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.icon-and-actions {
  grid-column: right;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button-container {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  width: 80%;

  > button:last-child {
    margin-top: 2rem;
  }
}

.footer {
  grid-row: footer;
  grid-column: span;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem;
}