@use '../../style.scss';
@import 'https://js.arcgis.com/4.22/esri/themes/light/main.css';

.map-wrapper-influence {
  height: 500px;
  width: 100%;
  border: solid 1px style.$bluegray-800;
}

.esri-view .esri-view-surface--inset-outline:focus::after {
  outline: none !important;
}
.influence-area-wrapper {
  margin-left: 20px;
  width: 600px;
  flex-direction: column;
}

.submit-influence-area-button {
  @extend %button-shared;
  color: style.$bluegray-800;
  background: style.$white;
  margin-top: 10px;
}
.submit-influence-area-button-clicked {
  @extend %button-shared;
  color: style.$bluegray-800;
  background: rgb(85, 198, 85);
  margin-top: 10px;
}
