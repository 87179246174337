.item {
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}
.phase-link {
  color: black;
  text-decoration: none;
  background-color: red;
}
