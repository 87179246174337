.loading-bar-container {
  position: relative;
  height: 20px; /* Set a suitable height */
  background-color: lightgray; /* Add a background color for the loading bar container */
}

.loading-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: green; /* Choose a color for the loading bar */
}

.loading-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white; /* Choose a color for the text */
  font-weight: bold;
}
