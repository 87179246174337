@use '../../style.scss';

.container {
  display: flex;
  margin: auto;
  flex-wrap: wrap; // pretty or annoying?
}

.picker-button,
.picker-button-active {
  // flex: 1 1 0px; //<- prettier without?
  background-color: style.$bluegray-50;
  color: style.$bluegray-800;
  border: none;
  font-weight: 500; // make bolder?
  font-size: 0.875rem;
  margin-right: 2px;
  padding: 0 12px;
  height: 32px;
  outline: none;
  border-radius: 12px;
  cursor: pointer;
  .icon-and-label {
    display: flex;
    align-items: center;
    .icon-option {
      margin-right: 6px;
    }
  }
}

.picker-button {
  background-color: transparent;
  color: style.$bluegray-800;
  &:hover {
    background-color: style.$bluegray-50;
  }
}

//arcgis styling -> nuke?
.container-arcgis {
  display: flex;
  flex: 1 1 0px;
  max-width: 100%;
  margin: auto 12px;
  position: absolute;
  z-index: 1;
  right: 215px;
  top: 15px;
  box-shadow: 0px 1px 2px gray;
  padding: 6px;
  background-color: white;
}

// colors from: https://js.arcgis.com/4.22/esri/themes/light/main.css
.picker-button-arcgis,
.picker-button-arcgis-active {
  flex: 1 1 0px;
  background-color: #fff;
  color: var(--calcite-ui-text-1);
  border: none;
  outline: none;
  height: 32px;
  min-width: 77px;
  cursor: pointer;
  &:hover {
    background-color: var(--calcite-ui-foreground-2);
  }
  &:focus {
    outline: none;
  }
}
.picker-button-arcgis-active {
  background-color: var(--calcite-ui-foreground-3);
  &:hover {
    background-color: var(--calcite-ui-foreground-3);
  }
}
