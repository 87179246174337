@use '../../style.scss';

.project-browser-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 9999; /* Ensure the project browser appears above other content */
}

.project-browser {
  background-color: white;
  max-width: 100%;
  min-width: 500px;
  border: 2px solid black;
}

.sideMenu {
  margin: 0.4rem;
  min-width: 240px;
  transition: width 0.3s ease-in-out;
  height: calc(100vh - style.$header-size - 52px);
  box-shadow: 0 0 4px #818285;
  background-color: white;
  border-right: 1px solid lightgray;
  padding-top: 10px;
  padding-bottom: 12px;
  position: sticky;
  top: 0;
  animation-name: fade-in;
  border-radius: 10px;
}

@media (max-width: 260px) {
  .sideMenu {
    flex-direction: column;
  }
}

.sideMenuClosed {
  @extend .sideMenu;
  min-width: 0;
  transition: width 0.3s ease-in-out;
  width: 60px;
}
.add-project-version {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.menuButtonContainer {
  margin-left: auto;
  position: absolute;
  right: 20px;
}

.menuContent {
  height: 100%;
}

.edit-icon {
  margin-right: 1rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
  &:hover {
    background-color: style.$bluegray-50;
  }
  align-self: center;
}

.menuPart {
  padding: 0 1rem;
}

.topPart {
  @extend .menuPart;
  border-bottom: 1px solid lightgray;
  margin-top: 3rem;
}

.flexAlignCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.topPartItem {
}

.topPart.closed {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.middlePart {
  @extend .menuPart;
  //border-bottom: 1px solid lightgray;
  //height: 100%;
  align-items: baseline;
}

.middlePart.closed {
  display: flex;
  flex-direction: column;
}

.middlePartItem {
  margin: 1.5rem 0;
  border-radius: 10px;
}

.bottomPart {
  @extend .menuPart;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.fadeIn {
  animation: fade-in 0.4s ease-in-out;
}

.form-container {
  display: block;
}

.snd-data-container {
  max-height: calc(100% - 14rem);
  overflow-y: scroll;
}

.snd-data-open {
  /* Add any open styles you want here */
}

.snd-data-closed {
  /* Add any closed styles you want here */
}

.checkbox-row {
  @extend .menuPart;
  align-items: baseline;
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Vertically center items */
  justify-content: space-between;
}

.checkbox-row label {
  margin-right: 10px; /* Add spacing between checkbox and borehole name */
  margin-left: -20px;
  order: 1; /* Move the label to the right (default order is 0) */
}

.checkbox-row input[type='checkbox'] {
  margin-right: 20px; /* Add spacing between checkbox and borehole name */
  margin-left: 10px;
  order: 0; /* Keep the checkbox to the left (default order is 0) */
}
