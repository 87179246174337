@use '../../style.scss';

.close-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
}

.close-icon {
  color: #333;
  font-size: 20px;
}

.browser-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px;
  background: style.$orange-700;
  color: white;
  font-size: 14px;
}

.bedrock-options {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 10px;
}

.header-title {
  margin-right: 10px;
}

.browser-content {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
  color: black;
  font-size: 14px;
}
