@use '../../style.scss';

.new-suggestion-container {
  background-color: style.$bluegray-50;
  border-radius: 10px;
  margin: 0.5em;
  padding: 0.5em 1em;
  cursor: pointer;
}
.new-suggestion-icon-button {
  width: 100%;
  height: 100%;
  margin: auto;
  border: 0;
  color: style.$bluegray-300;
  background: transparent;
  cursor: pointer;
}
