@use '../../style.scss';

.sidebar {
  height: 100%;
  width: style.$sidebar-width;
  min-width: style.$sidebar-width;
  left: 0;
  border-right: solid 2px #063342;
}
.new-alternative {
  font-size: small;
}
.alternative-card {
  border-bottom: solid 1px #063342;
  padding: 10px;
  cursor: pointer;
}

.alternative-header {
  font-size: 1.2em;
  font-weight: bold;
}
.show-export-table-button {
  bottom: 0;
  position: absolute;
  margin-bottom: 60px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  font-size: small;
  cursor: pointer;
}
.title {
  padding-left: 15px;
}
.new-suggestion-container {
  background-color: style.$bluegray-50;
  border-radius: 10px;
  margin: 0.5em;
  padding: 0.5em 1em;
  cursor: pointer;
}
.new-suggestion-icon-button {
  width: 100%;
  height: 100%;
  margin: auto;
  border: 0;
  color: style.$bluegray-300;
  background: transparent;
  cursor: pointer;
}
.table-button {
  padding: 12px 16px;
  box-shadow: 0 0 4px gray;
  font-size: larger;
  height: 48px;
  color: white;
  background: #063342;
  border-radius: 4px;
  border: 0;
  left: 0;
  width: 140px;
  cursor: pointer;
}
