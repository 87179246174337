@use '../../style.scss';

.total-sounding-plot {
  display: flex;
  flex-direction: column;
}

.buttons {
  display: flex;
  flex-direction: row;
  margin-right: 0px;
  justify-content: center;
  gap: 10px;
}
.two-and-two-buttons {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.btn-update {
  height: 30px;
  width: 140px;
  border-radius: 4px;
  background-color: style.$orange-500;
  border: 1px solid style.$orange-800;
}

.btn-update:hover {
  background-color: style.$orange-800; /* Change the background color on hover */
}

.buttons-and-dropdown {
  display: flex;
  flex-direction: column;
}
.time-and-pressure {
  width: 200px;
}
.time-diagram {
  transform: rotate(90deg);
}

.legend-item {
  display: flex;
}
.legend-item-text {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
}
.legend-item-color {
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  width: 10px;
  height: 10px;
}
.slag-og-spyl {
}
.pressure {
}
.slag .spyl {
  width: 100px;
  margin: 0;
}
.plot-and-label {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.status {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.dropdowns {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 5px;
}
