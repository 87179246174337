@use '../../style.scss';

.alternative-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 80px black;
  border-radius: 20px;
  background-color: white;
  padding: 40px;
  display: flex;
  justify-content: space-between;
}
.form {
  width: 260px;
  height: 340px;
}
.close-form-button {
  position: absolute;
  right: 16px;
  top: 16px;
  border: none;
  background: transparent;
  color: style.$bluegray-800;
  cursor: pointer;
}
.input-field {
  height: 24px;
  border: 2px solid rgba(17, 17, 19, 0.2);
  border-radius: 4px;
}
.submit-form-button {
  @extend %button-shared;
  position: absolute;
  bottom: 40px;
  width: calc(100% - 80px);
}
input[type='file'] {
  display: none;
}
.file-button {
  padding: 12px 16px;
  text-align: center;
  box-shadow: 0 0 4px style.$gray-600;
  justify-content: center;
  font-size: larger;
  color: style.$white;
  background: style.$bluegray-800;
  border-radius: 4px;
  border: 0;
  margin-top: 10px;
  scale: 0.7;
  cursor: pointer;
}
.file {
  padding: 0;
  margin: 0;
  font-size: small;
}
.link-icon {
  margin: 0 0.5em;
  color: style.$bluegray-800;
}
.select-input {
  height: 30px;
}
