@use '../../style.scss';

.form {
  display: flex;
  flex-direction: column;
  width: 260px;
  margin-top: 20px;
}
.input {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}
.close-form-button {
  position: absolute;
  right: 20px;
  top: 20px;
  border: 0;
  color: style.$bluegray-800;
  background: style.$white;
  cursor: pointer;
}
.input-field-name {
  font-size: larger;
  font-weight: 300;
}
.input-field {
  min-height: 20px;
  left: 0%;
  right: 0%;
  top: 0px;
  border: 2px solid rgba(17, 17, 19, 0.2);
  border-radius: 4px;
}
.submit-form-button {
  padding: 12px 16px;
  box-shadow: 0 0 4px style.$gray-600;
  justify-content: center;
  font-size: larger;
  height: 48px;
  color: style.$white;
  background: style.$bluegray-800;
  border-radius: 4px;
  border: 0;
  max-width: 200px;
  cursor: pointer;
  &:disabled {
    background-color: style.$bluegray-400;
    cursor: default;
  }
}

.link-icon {
  margin: 0 0.5em;
  color: style.$bluegray-800;
}
.influence-area-button {
  @extend %button-shared;
  color: style.$bluegray-800;
  background: style.$white;
}

.form-map-div {
  @extend %modal-shared;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.influence-map {
  display: flex;
  flex-direction: column;
  align-items: center;
}
