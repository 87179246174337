$header-size: 3.72em;
$sidebar-width: 16em;
$table-height: 40%;

// Colors
$white: #ffffff;

// Multiconsult:
$bluegray-10: #f2f4f5;
$bluegray-50: #e6eaed;
$bluegray-100: #cbd6dd;
$bluegray-200: #b3c2cb;
$bluegray-300: #8499a5;
$bluegray-400: #6d8591;
$bluegray-500: #58717d;
$bluegray-600: #425b67;
$bluegray-700: #2d4651;
$bluegray-800: #063342;

$orange-100: #ffe7cb;
$orange-200: #ffdcb4;
$orange-300: #fdc98a;
$orange-400: #fdbf76;
$orange-500: #fbb562;
$orange-600: #faab4b;
$orange-700: #f9a234;
$orange-800: #f8992e;

$gray-100: #e7e7e8;
$gray-200: #d1d2d4;
$gray-300: #bcbdc0;
$gray-400: #a7a9ac;
$gray-500: #939598;
$gray-600: #818285;
$gray-700: #6d6e71;
$gray-800: #58595b;

// Prosjekt (Obs! Bruker Multiconsultfargene i komponenter i tillegg til disse)

$green-100: #d6ebdf;
$green-200: #bfe0cf;
$green-300: #8ccaad;
$green-400: #6bbf9d;
$green-500: #3db48c;
$green-600: #16a97c;
$green-700: #009f6c;
$green-800: #00975f;

$purple-100: #d8cdd8;
$purple-200: #c4b5c5;
$purple-300: #9e869f;
$purple-400: #8b708e;
$purple-500: #795c7f;
$purple-600: #684970;
$purple-700: #573964;
$purple-800: #4b3364;

$blue-100: #c7cfe9;
$blue-200: #aebbdf;
$blue-300: #8298ce;
$blue-400: #6d89c6;
$blue-500: #577bbd;
$blue-600: #3d6fb7;
$blue-700: #2165b0;
$blue-800: #005aab;

$red-100: #fbd3c7;
$red-200: #fabeb1;
$red-300: #f69788;
$red-400: #f48476;
$red-500: #f37066;
$red-600: #f15a56;
$red-700: #ef3f48;
$red-800: #ed1a3b;

// Tilstandsgrader

$tilstandsgrad-0: #14be14;
$tilstandsgrad-1: #b8e926;
$tilstandsgrad-2: #faab35;
$tilstandsgrad-3: #ff0000;

// Other (Annet)

$link-color: #b25f00;

// Verdi

$verdi-0: #ffcc50;
$verdi-1: #ff9933;
$verdi-2: #ff5050;
$verdi-3: #ff75d1;

%button-shared {
  height: 48px;
  box-shadow: 0 0 4px $gray-600;
  border: none;
  border-radius: 4px;
  background: $bluegray-800;
  padding: 12px 16px;
  justify-content: center;
  color: $white;
  cursor: pointer;
  &:disabled {
    background-color: $bluegray-400;
    cursor: default;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bluegray-10;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

%modal-shared {
  display: flex;
  padding: 40px;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: 0 0 80px black;
  border-radius: 20px;
}
